import React from "react";
import { Modal, Button } from "react-bootstrap";
import "./ActivationModal.css";

const ActivationModal = ({ activationStatus, getUserDataAgain }) => {
  const handleRefresh = async () => {
    // Handle modal refresh event here if needed
    const userData = getUserDataAgain();
    console.log(userData);
  };

  return (
    <>
      {activationStatus === "not active" && (
        <div className="activation-modal-overlay">
          <Modal.Dialog className="activation-modal-dialog">
            <Modal.Header
              closeButton={false}
              className="activation-modalHeader"
            >
              <Modal.Title className="activation-modal-title">
                חשבון לא פעיל
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p className="activation-modal-message">
                יש ליצור קשר עמנו בכתובת
                <br />
                <a href="mailto:simplycoachofficial@gmail.com">
                  simplycoachofficial@gmail.com
                </a>
                <br />
                לצורך הפעלת החשבון שלך.
              </p>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="primary"
                onClick={handleRefresh}
                className="activation-modal-button"
              >
                רענן
              </Button>
            </Modal.Footer>
          </Modal.Dialog>
        </div>
      )}
    </>
  );
};

export default ActivationModal;
