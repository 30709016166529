import Spinner from "react-bootstrap/Spinner";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";

import { getAge } from "../NewStudentForm";

// Custom
import "../general.css";

import { submitNewStudentToGroup } from "../../backend/firebaseFunctions";
import { useState } from "react";

export default function DetailsApprovePhase(props) {
  const formState = props.formState;
  const setFormState = props.setFormState;

  const coachType = props.coachType;

  const newStudentData = props.newStudentData;
  const newParentsData = props.newParentsData;

  const setProgress = props.setProgress;

  const ID = props.ID;

  const userType = props.userType;

  const groupID = props.groupID;

  const studentPhoto = props.studentPhoto;

  const studentAge = getAge(
    new Date(
      newStudentData.birthYear,
      newStudentData.birthMonth - 1,
      newStudentData.birthDay
    )
  );
  const [userAgreesTerms1, setUserAgreesTerms1] = useState(false);
  const [userAgreesTerms2, setUserAgreesTerms2] = useState(false);
  const [userAgreesTerms3, setUserAgreesTerms3] = useState(false);
  return (
    <div className="contentContainer">
      {formState === "normal" ? (
        <>
          <p className="newtext">אישור הפרטים</p>
          <div className="approveContainer">
            <img src={studentPhoto} className="imagePreviewAAprove" alt="" />
            <p className="newtext newmediumText">פרטי החניך</p>
            <div className="studentDetails">
              <div className="detailsRow">
                <p className="newtext newsmallerText">שם מלא</p>
                <p className="newtext newsmallerText">{`${newStudentData.personalName} ${newStudentData.familyName}`}</p>
              </div>
              <div className="detailsRow">
                <p className="newtext newsmallerText">תאריך לידה</p>
                <p className="newtext newsmallerText">{`${new Date(
                  Number(newStudentData.birthYear),
                  Number(newStudentData.birthMonth - 1),
                  Number(newStudentData.birthDay)
                ).toLocaleDateString()}`}</p>
              </div>
              <div className="detailsRow">
                <p className="newtext newsmallerText">מין</p>
                <p className="newtext newsmallerText">
                  {newStudentData.isBoy ? `זכר` : `נקבה`}
                </p>
              </div>
              <div className="detailsRow">
                <p className="newtext newsmallerText">תעודת זהות</p>
                <p className="newtext newsmallerText">
                  {newStudentData.IDnumber}
                </p>
              </div>
              <div className="detailsRow">
                <p className="newtext newsmallerText">כתובת מגורים</p>
                <p className="newtext newsmallerText addressDisplay">
                  {newStudentData.address}
                </p>
              </div>
              {coachType === "1" ? (
                <>
                  <div className="detailsRow">
                    <p className="newtext newsmallerText">חגורה</p>
                    <p className="newtext newsmallerText">
                      {newStudentData.specialFields.beltLevel}
                    </p>
                  </div>
                  <div className="detailsRow">
                    <p className="newtext newsmallerText">משקל</p>
                    <p className="newtext newsmallerText">{`${newStudentData.specialFields.weightKG}`}</p>
                  </div>
                </>
              ) : null}
              {coachType === "2" || coachType === "3" ? (
                <>
                  <div className="detailsRow">
                    <p className="newtext newsmallerText">מידת חולצה</p>
                    <p className="newtext newsmallerText">
                      {newStudentData.specialFields.shirtSize}
                    </p>
                  </div>
                  <div className="detailsRow">
                    <p className="newtext newsmallerText">מידת מכנסיים</p>
                    <p className="newtext newsmallerText">{`${newStudentData.specialFields.pantsSize}`}</p>
                  </div>
                </>
              ) : null}
            </div>
            <p className="newtext newmediumText">
              {studentAge >= 19
                ? "פרטי קשר"
                : newParentsData.parentOneMail &&
                  newParentsData.parentOnePhone &&
                  newParentsData.parentOneName &&
                  newParentsData.parentTwoMail &&
                  newParentsData.parentTwoPhone &&
                  newParentsData.parentTwoName
                ? "פרטי הורים"
                : "פרטי הורה"}
            </p>
            <div className="parentsDetails">
              {newParentsData.parentOneMail &&
              newParentsData.parentOnePhone &&
              newParentsData.parentOneName ? (
                <>
                  {studentAge < 19 ? (
                    <p className="newtext newsmallerText">
                      {newParentsData.parentOneName}
                    </p>
                  ) : null}
                  <p className="newtext newsmallerText">
                    {newParentsData.parentOnePhone}
                  </p>
                  <p className="newtext newsmallerText">
                    {newParentsData.parentOneMail}
                  </p>
                </>
              ) : null}
              {newParentsData.parentTwoMail &&
              newParentsData.parentTwoPhone &&
              newParentsData.parentTwoName ? (
                <>
                  <p className="newtext newsmallerText">
                    {newParentsData.parenewntTwoName}
                  </p>
                  <p className="newtext newsmallerText">
                    {newParentsData.parentTwoPhone}
                  </p>
                  <p className="newtext newsmallerText">
                    {newParentsData.parentTwoMail}
                  </p>
                </>
              ) : null}
            </div>
          </div>
          <div className="paddingTop paddingBottom termsAgreeContainer">
            <Form.Check
              type="checkbox"
              className="checkBox"
              onChange={(current) => {
                setUserAgreesTerms1(current.target.checked);
              }}
            />
            <p className="newtext newsmallerText">
              אני מודע/ת לכך כי הפרטים המזהים שהזנתי כעת והתמונה שצירפתי בתהליך
              הרישום ישמשו למאגר מידע פנימי של ארגון המאמן, על ידי השלמת ביצוע
              ההרשמה אני נותן/ת ומביע/ה את הסכמתי לכך.
            </p>
          </div>
          <div className="paddingBottom termsAgreeContainer">
            <Form.Check
              type="checkbox"
              className="checkBox"
              onChange={(current) => {
                setUserAgreesTerms2(current.target.checked);
              }}
            />
            <p className="newtext newsmallerText">
              ידוע לי, שבמהלך הפעילות השנתית המאמן/ת מצלם/ת את פעילויות הקבוצה
              ומשתף את קהילת ההורים ברשתות החברתיות בסרטונים ובתמונות בהם
              מופיעים החניכים. במידה ולא אהיה מעוניין/ת בכך, איידע את המאמן/ת.
            </p>
          </div>
          <div className="paddingBottom termsAgreeContainer">
            <Form.Check
              type="checkbox"
              className="checkBox"
              onChange={(current) => {
                setUserAgreesTerms3(current.target.checked);
              }}
            />
            <p className="newtext newsmallerText">
              אני מאשר למאמן / מועדון לבצע את ההרשמה של בני / בתי בפורטל
              ההתאחדות / במנהל הספורט של הענף.
            </p>
          </div>
          <Button
            disabled={
              !userAgreesTerms1 || !userAgreesTerms2 || !userAgreesTerms3
            }
            variant="primary"
            className="newtext newsmallerText"
            onClick={async () => {
              setFormState("sending");
              let finalStudentData = {
                age: getAge(
                  new Date(
                    newStudentData.birthYear,
                    newStudentData.birthMonth - 1,
                    newStudentData.birthDay
                  )
                ),
                birthday: new Date(
                  newStudentData.birthYear,
                  newStudentData.birthMonth - 1,
                  newStudentData.birthDay
                ),
                firstParentName: newParentsData.parentOneName,
                firstParentNumber: newParentsData.parentOnePhone,
                firstParentEmailAddress: newParentsData.parentOneMail,
                secondParentName: newParentsData.parentTwoName,
                secondParentNumber: newParentsData.parentTwoPhone,
                secondParentEmailAddress: newParentsData.parentTwoMail,
                gender: newStudentData.isBoy ? "זכר" : "נקבה",
                name: `${newStudentData.personalName} ${newStudentData.familyName}`,
                weightKG: newStudentData.weightKG,
                IDnumber: newStudentData.IDnumber,
                address: newStudentData.address,
                specialFields: newStudentData.specialFields,
              };

              if (coachType === "1") {
                finalStudentData.beltColor = newStudentData.beltColor;
              }

              const sent = await submitNewStudentToGroup(
                userType,
                ID,
                groupID,
                finalStudentData,
                studentPhoto
              );
              if (sent) {
                setProgress(100);
              } else setFormState("failed");
            }}
          >
            הפרטים נכונים, הירשם לקבוצה
          </Button>
        </>
      ) : null}
      {formState === "sending" ? (
        <>
          <div className="spinnerContainer">
            <Spinner />
          </div>
        </>
      ) : null}
      {formState === "failed" ? (
        <>
          <p className="text">בעיה ברישום, נסו שנית</p>
        </>
      ) : null}
    </div>
  );
}
