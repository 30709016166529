import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import "./HelpBubble.css";

const HelpBubble = ({ text }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <Button
        variant="link"
        onClick={handleOpenModal}
        style={styles.helpButton}
      >
        ?
      </Button>

      <Modal show={isModalOpen} onHide={handleCloseModal} centered>
        <Modal.Header className="ml-auto modalHeader">
          <Modal.Title>עזרה</Modal.Title>
          <Button
            variant="light"
            className="close-button"
            onClick={handleCloseModal}
          >
            &times;
          </Button>
        </Modal.Header>

        <Modal.Body style={styles.modalBody}>
          <p style={styles.modalText}>{text}</p>
        </Modal.Body>
        <Modal.Footer style={styles.modalFooter}>
          <Button variant="secondary" onClick={handleCloseModal}>
            סגור
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

const styles = {
  helpButton: {
    fontSize: "24px",
    color: "white",
    backgroundColor: "#639fff",
    borderRadius: "50%",
    width: "40px",
    height: "40px",
    padding: "0",
    cursor: "pointer",
    textDecoration: "none",
  },
  modalHeader: {
    backgroundColor: "#f8f8f8",
    borderBottom: "1px solid #ddd",
    display: "flex",
    flexDirection: "row-reverse",
    justifyContent: "center",
    alignItems: "center",
  },
  modalBody: {
    backgroundColor: "#fff",
    padding: "20px",
    textAlign: "center",
  },
  modalText: {
    fontSize: "16px",
    lineHeight: "1.6",
    direction: "rtl",
  },
  modalFooter: {
    backgroundColor: "#f8f8f8",
    borderTop: "1px solid #ddd",
  },
};

export default HelpBubble;
