export function getDaysInMonth(month) {
  // Check if the month is valid (between 1 and 12)
  if (month < 1 || month > 12) {
    return "Invalid month";
  }

  // Create a date object for the given month
  let date = new Date(2022, month, 0);

  // Return the number of days in the month
  return date.getDate();
}

export function getHourString(timestamp) {
  // Get date from timestamp
  const date = new Date(timestamp * 1000);

  // Extract the hour and minute from the date object
  const hour = date.getHours();
  const minute = date.getMinutes();

  // Use the padStart method to add leading zeroes if necessary
  const hourString = hour.toString().padStart(2, "0");
  const minuteString = minute.toString().padStart(2, "0");

  // Return the formatted hour string
  return `${hourString}:${minuteString}`;
}
