// CSS
import "../general.css";

// React
import { useState } from "react";

// Bootstrap
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";

// Time
import { getDaysInMonth } from "../../backend/timeFunctions";

// Select
import Select from "react-select";

function isValidID(idNumber) {
  // Check if the input is a string and has exactly 9 digits
  if (idNumber.length > 9) {
    return false;
  }
  return true;
}

function StudentDetailsPhase(props) {
  const setProgress = props.setProgress;
  const newStudentData = props.newStudentData;
  const setNewStudentData = props.setNewStudentData;
  const studentPhoto = props.studentPhoto;
  const setStudentPhoto = props.setStudentPhoto;
  const groupData = props.groupData;
  const coachType = props.coachType;

  // Form States
  const [personalName, setPersonalName] = useState(
    newStudentData.personalName ? newStudentData.personalName : ""
  );
  const [personalNameTouched, setPersonalNameTouched] = useState(
    newStudentData.personalName ? true : false
  );
  const [personalNameValid, setPersonalNameValid] = useState(true);

  const [familyName, setFamilyName] = useState(
    newStudentData.familyName ? newStudentData.familyName : ""
  );
  const [familyNameTouched, setFamilyNameTouched] = useState(
    newStudentData.familyName ? true : false
  );
  const [familyNameValid, setFamilyNameValid] = useState(true);

  const [IDnumber, setIDnumber] = useState(
    newStudentData.IDnumber ? newStudentData.IDnumber : ""
  );
  const [IDnumberTouched, setIDnumberTouched] = useState(
    newStudentData.IDnumber ? true : false
  );
  const [IDnumberValid, setIDnumberValid] = useState(true);

  const [address, setAddress] = useState(
    newStudentData.address ? newStudentData.address : ""
  );
  const [addressTouched, setAddressTouched] = useState(
    newStudentData.address ? true : false
  );
  const [addressValid, setAddressValid] = useState(true);

  // For Martial Arts Coaches
  const [beltLevel, setBeltLevel] = useState(
    newStudentData?.specialFields?.beltLevel
      ? newStudentData?.specialFields?.beltLevel
      : "K10"
  );
  const [weightKG, setWeightKG] = useState(
    newStudentData?.specialFields?.weightKG
      ? newStudentData?.specialFields?.weightKG
      : ""
  );
  const [weightKGTouched, setWeightKGTouched] = useState(
    newStudentData?.specialFields?.weightKG ? true : false
  );
  const [weightKGValid, setWeightKGValid] = useState(true);

  // For Ball Games Coaches and Dance Coaches
  const [shirtSize, setShirtSize] = useState(
    newStudentData?.specialFields?.shirtSize
      ? newStudentData?.specialFields?.shirtSize
      : "S"
  );
  const [pantsSize, setPantsSize] = useState(
    newStudentData?.specialFields?.pantsSize
      ? newStudentData?.specialFields?.pantsSize
      : "32"
  );

  const [birthYear, setBirthYear] = useState(
    newStudentData.birthYear ? newStudentData.birthYear : ""
  );
  const [birthYearTouched, setBirthYearTouched] = useState(
    newStudentData.birthYear ? true : false
  );
  const [birthYearValid, setBirthYearValid] = useState(true);

  const [birthMonth, setBirthMonth] = useState(
    newStudentData.birthMonth ? newStudentData.birthMonth : ""
  );
  const [birthMonthTouched, setBirthMonthTouched] = useState(
    newStudentData.birthMonth ? true : false
  );
  const [birthMonthValid, setBirthMonthValid] = useState(true);

  const [birthDay, setBirthDay] = useState(
    newStudentData.birthDay ? newStudentData.birthDay : ""
  );
  const [birthDayTouched, setBirthDayTouched] = useState(
    newStudentData.birthDay ? true : false
  );
  const [birthDayValid, setBirthDayValid] = useState(true);

  const [isBoy, setIsBoy] = useState(
    newStudentData.isBoy ? newStudentData.isBoy : true
  );

  return (
    <div className="contentContainer">
      <p className="newtext title">פרטי החניך</p>
      <Form className="formStyles">
        <Form.Group className="detailsForm">
          <Form.Group className="genderForm" controlId="gender">
            <Form.Check
              reverse
              label="זכר"
              name="group1"
              type="radio"
              id="isBoy"
              className="newtext newsmallerText"
              checked={isBoy}
              onChange={(e) => {
                e.target.value === "on" && setIsBoy(true);
              }}
            />
            <Form.Check
              reverse
              label="נקבה"
              name="group1"
              type="radio"
              id="isGirl"
              className="newtext newsmallerText"
              checked={!isBoy}
              onChange={(e) => {
                e.target.value === "on" && setIsBoy(false);
              }}
            />
          </Form.Group>
          <Form.Group className="namesForm">
            <Form.Control
              type="text"
              className="newtext newsmallerText newblackText formField"
              placeholder="שם פרטי"
              value={personalName}
              id="personalName"
              onChange={(e) => {
                setPersonalName(e.target.value);
                if (personalName.trim().length > 0) setPersonalNameValid(true);
                else setPersonalNameValid(false);
              }}
              onBlur={() => {
                if (!personalNameTouched) setPersonalNameTouched(true);
                if (personalName.trim().length > 0) setPersonalNameValid(true);
                else setPersonalNameValid(false);
              }}
              isInvalid={!personalNameValid}
            />
            <Form.Control
              className="newtext newsmallerText newblackText formField"
              type="text"
              value={familyName}
              placeholder="שם משפחה"
              id="familyName"
              onChange={(e) => {
                setFamilyName(e.target.value);
                if (familyName.trim().length > 0) setFamilyNameValid(true);
                else setFamilyNameValid(false);
              }}
              onBlur={() => {
                if (!familyNameTouched) setFamilyNameTouched(true);
                if (familyName.trim().length > 0) setFamilyNameValid(true);
                else setFamilyNameValid(false);
              }}
              isInvalid={!familyNameValid}
            />
          </Form.Group>
        </Form.Group>
        <Form.Group className="IDandAddressForm">
          <Form.Control
            className="newtext newsmallerText newblackText formField IDnumberInput"
            type="text"
            value={IDnumber}
            placeholder="מספר תעודת זהות"
            id="IDnumber"
            onChange={(e) => {
              setIDnumber(e.target.value);
              if (isValidID(e.target.value)) setIDnumberValid(true);
              else setIDnumberValid(false);
            }}
            onBlur={() => {
              if (!IDnumberTouched) setIDnumberTouched(true);
              if (isValidID(IDnumber)) setIDnumberValid(true);
              else setIDnumberValid(false);
            }}
            isInvalid={!IDnumberValid}
            dir="rtl"
          />
          <Form.Control
            className="newtext newsmallerText newblackText formField"
            type="text"
            value={address}
            placeholder="כתובת מגורים"
            id="address"
            onChange={(e) => {
              setAddress(e.target.value);
              if (e.target.value.trim().length > 0) setAddressValid(true);
              else setAddressValid(false);
            }}
            onBlur={() => {
              if (!addressTouched) setAddressTouched(true);
              if (address.trim().length > 0) setAddressValid(true);
              else setAddressValid(false);
            }}
            isInvalid={!addressValid}
            dir="rtl"
          />
        </Form.Group>
        {coachType === "1" ? (
          <Form.Control
            className="newtext newsmallerText newblackText formField weightField"
            type="number"
            value={weightKG}
            placeholder="משקל בקילוגרם"
            id="weightKG"
            onChange={(e) => {
              setWeightKG(e.target.value);
              if (weightKG.trim().length > 0) setWeightKGValid(true);
              else setWeightKGValid(false);
            }}
            onBlur={() => {
              if (!weightKGTouched) setWeightKGTouched(true);
              if (weightKG.trim().length > 0) setWeightKGValid(true);
              else setWeightKGValid(false);
            }}
            isInvalid={!weightKGValid}
          />
        ) : null}
        {coachType === "1" ? (
          <>
            <Form.Label className="newtext newsmallerText label">
              דרגה
            </Form.Label>
            <Select
              options={[
                "K10",
                "K9",
                "K8",
                "K7",
                "K6",
                "K5",
                "K4",
                "K3",
                "K2",
                "K1",
                "D1",
                "D2",
                "D3",
                "D4",
                "D5",
                "D6",
                "D7",
                "D8",
                "D9",
                "D10",
              ].map((beltLevel) => {
                return {
                  value: beltLevel,
                  label: beltLevel,
                };
              })}
              value={{ value: beltLevel, label: beltLevel }}
              onChange={({ value }) => {
                setBeltLevel(value);
              }}
              defaultValue={`K10`}
              placeholder={`דרגה`}
              className="beltPicker"
            />
          </>
        ) : null}
        {coachType === "2" || coachType === "3" ? (
          <div className="clothingSizesContainer">
            <div className="shirtOrPantsContainer">
              <Form.Label className="newtext newsmallerText shirtOrPantsLabel">
                מידת חולצה
              </Form.Label>
              <Form.Label className="newtext newsmallerText shirtOrPantsLabel">
                מידת מכנסיים
              </Form.Label>
            </div>
            <div className="shirtOrPantsContainer">
              <Select
                options={["XXS", "XS", "S", "M", "L", "XL", "XXL", "XXXL"].map(
                  (shirtSize) => {
                    return {
                      value: shirtSize,
                      label: shirtSize,
                    };
                  }
                )}
                value={{ value: shirtSize, label: shirtSize }}
                onChange={({ value }) => {
                  setShirtSize(value);
                }}
                defaultValue={`S`}
                placeholder={`מידת חולצה`}
                className="shirtOrPantsPicker"
              />
              <Select
                options={[
                  "30",
                  "31",
                  "32",
                  "33",
                  "34",
                  "35",
                  "36",
                  "37",
                  "38",
                  "39",
                  "40",
                  "41",
                  "42",
                  "43",
                  "44",
                  "45",
                  "46",
                  "47",
                  "48",
                  "49",
                  "50",
                ].map((pantsSize) => {
                  return {
                    value: pantsSize,
                    label: pantsSize,
                  };
                })}
                value={{ value: pantsSize, label: pantsSize }}
                onChange={({ value }) => {
                  setPantsSize(value);
                }}
                defaultValue={`30`}
                placeholder={`מידת מכנסיים`}
                className="shirtOrPantsPicker"
              />
            </div>
          </div>
        ) : null}
        <Form.Label className="newtext newsmallerText label">
          תמונת החניך
        </Form.Label>
        {studentPhoto ? (
          <img src={studentPhoto} className="imagePreview" alt="" />
        ) : (
          <div></div>
        )}
        <Form.Group className="mb-3 imagePicker">
          <Form.Control
            hidden={false}
            type="file"
            placeholder="תמונה"
            accept="image/*"
            id="studentPhoto"
            onChange={(e) => {
              setStudentPhoto(URL.createObjectURL(e.target.files[0]));
            }}
          />
        </Form.Group>
        <Form.Label className="newtext newsmallerText label">
          תאריך לידה
        </Form.Label>
        <Form.Group className="mb-3 dateForm">
          <Form.Control
            type="number"
            className="newtext newsmallerText newblackText formField dateFields"
            placeholder="שנה"
            id="birthYear"
            value={birthYear}
            onChange={(e) => {
              setBirthYear(e.target.value);
              if (
                Number(birthYear.trim()) >= 1950 &&
                Number(birthYear.trim()) < new Date().getFullYear()
              )
                setBirthYearValid(true);
              else setBirthYearValid(false);
            }}
            onBlur={() => {
              if (!birthYearTouched) setBirthYearTouched(true);
              if (
                Number(birthYear.trim()) >= 1950 &&
                Number(birthYear.trim()) < new Date().getFullYear()
              )
                setBirthYearValid(true);
              else setBirthYearValid(false);
            }}
            isInvalid={!birthYearValid}
          />
          <Form.Control
            className="newtext newsmallerText newblackText formField dateFields"
            type="number"
            placeholder="חודש"
            id="birthMonth"
            value={birthMonth}
            onChange={(e) => {
              setBirthMonth(e.target.value);
              if (birthDay <= getDaysInMonth(birthMonth) && birthDay >= 1)
                setBirthDayValid(true);
              else setBirthDayValid(false);
            }}
            onBlur={() => {
              if (!birthMonthTouched) setBirthMonthTouched(true);
              if (
                Number(birthMonth.trim()) >= 1 &&
                Number(birthMonth.trim()) <= 12
              )
                setBirthMonthValid(true);
              else setBirthMonthValid(false);
              if (birthDay <= getDaysInMonth(birthMonth) && birthDay >= 1)
                setBirthDayValid(true);
              else setBirthDayValid(false);
            }}
            isInvalid={!birthMonthValid}
          />
          <Form.Control
            className="newtext newsmallerText newblackText formField dateFields"
            type="number"
            placeholder="יום"
            id="birthDay"
            value={birthDay}
            onChange={(e) => {
              setBirthDay(e.target.value);
              if (birthDay <= getDaysInMonth(birthMonth) && birthDay >= 1)
                setBirthDayValid(true);
              else setBirthDayValid(false);
            }}
            onBlur={() => {
              if (!birthDayTouched) setBirthDayTouched(true);
              if (birthDay <= getDaysInMonth(birthMonth) && birthDay >= 1)
                setBirthDayValid(true);
              else setBirthDayValid(false);
            }}
            isInvalid={!birthDayValid}
          />
        </Form.Group>
        <Button
          variant="primary"
          className="newtext newsmallerText"
          disabled={
            !(
              personalNameValid &&
              personalNameTouched &&
              familyNameValid &&
              familyNameTouched &&
              birthYearValid &&
              birthYearTouched &&
              birthMonthValid &&
              birthMonthTouched &&
              birthDayValid &&
              birthDayTouched &&
              addressValid &&
              addressTouched &&
              IDnumberValid &&
              IDnumberTouched &&
              studentPhoto
            )
          }
          onClick={() => {
            let specialFields = {};
            // If this is martial arts coach
            if (coachType === "1") {
              specialFields.weightKG = weightKG;
              specialFields.beltLevel = beltLevel;
            }
            // If Ball Games or Dance coach
            else if (coachType === "2" || coachType === "3") {
              specialFields.shirtSize = shirtSize;
              specialFields.pantsSize = pantsSize;
            } else {
              specialFields = null;
            }
            setNewStudentData({
              personalName: personalName,
              familyName: familyName,
              weightKG: weightKG,
              beltColor: beltLevel,
              birthYear: birthYear,
              birthMonth: birthMonth,
              birthDay: birthDay,
              isBoy: isBoy,
              IDnumber: IDnumber,
              address: address,
              specialFields: specialFields,
            });
            setProgress(60);
          }}
        >
          המשך
        </Button>
      </Form>
    </div>
  );
}

export default StudentDetailsPhase;
