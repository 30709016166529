// CSS
import "../general.css";

// Functions
import { getDaysString } from "../NewStudentForm";
import { getHourString } from "../../backend/timeFunctions";

// Bootstrap
import Button from "react-bootstrap/Button";

export const WelcomePhase = (props) => {
  const groupData = props.groupData;
  const setProgress = props.setProgress;
  return (
    <div className="contentContainer">
      <h1 className="newtext paddingTop">טופס רישום</h1>
      <h1 className="newtext">{`לקבוצת ה${groupData?.name} ב${groupData?.dojo}`}</h1>
      {!groupData?.trainingSlots ? (
        <h1 className="newtext days">{`${getHourString(
          groupData.startTime
        )} - ${getHourString(groupData.endTime)}`}</h1>
      ) : (
        <></>
      )}
      {getDaysString(groupData)
        .split("&")
        .map((row) => {
          return (
            <div key={Math.random()}>
              <h1 className="newtext">{row}</h1>
            </div>
          );
        })}
      <Button
        variant="primary"
        className="newtext newsmallerText StartedButton"
        onClick={() => {
          setProgress(20);
        }}
      >
        בואו נתחיל
      </Button>
    </div>
  );
};
