import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Container, Nav, Tab, Button, Modal, Form } from "react-bootstrap";
import {
  getUserCoaches,
  getUserData,
  getUserEvents,
  getUserGroups,
  managerLogout,
  syncUserCoaches,
  syncUserEvents,
  syncUserGroups,
} from "../../backend/firebaseFunctions";
import "./ManagerDashboard.css";
import EventCard from "./EventCard";
import { BsFillRocketFill } from "react-icons/bs";
import { BiLogOutCircle } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import HelpBubble from "./HelpBubble";
import ActivationModal from "./ActivationModal";

const Dashboard = () => {
  const { userID } = useParams();
  const [userData, setUserData] = useState(null);
  const [initalDataRecieved, setInitialDataRecieved] = useState(false);
  const [showLogoutModal, setShowLogoutModal] = useState(false);

  let navigate = useNavigate();

  const handleLogout = async () => {
    // Add your logout logic here
    try {
      await managerLogout();
      navigate(`../manager/auth`);
      setShowLogoutModal(false);
    } catch (err) {
      return err;
    }
  };

  // states
  const [events, setEvents] = useState([]);
  const [showEventFormModal, setShowEventFormModal] = useState(false);
  const [eventName, setEventName] = useState("");
  const [eventTime, setEventTime] = useState("");
  const [eventDate, setEventDate] = useState("");
  const [eventDescription, setEventDescription] = useState("");
  const [editedEvent, setEditedEvent] = useState(null); // Track the edited event

  const [coaches, setCoaches] = useState([]);
  const [showCoachModal, setShowCoachModal] = useState(false);
  const [coachName, setCoachName] = useState("");
  const [coachPhoneNumber, setCoachPhoneNumber] = useState("");
  const [editedCoach, setEditedCoach] = useState(null); // Track the edited coach

  const [groups, setGroups] = useState([]);
  const [showGroupModal, setShowGroupModal] = useState(false);
  const [groupName, setGroupName] = useState("");
  const [groupClubName, setGroupClubName] = useState("");
  const [groupCoach, setGroupCoach] = useState("");
  const [selectedDays, setSelectedDays] = useState([]);
  const [startHours, setStartHours] = useState({});
  const [endHours, setEndHours] = useState({});
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [isGroupDetailsModalOpen, setIsGroupDetailsModalOpen] = useState(false);

  // Getting the inital data from the db
  useEffect(() => {
    (async function fetchData() {
      const userData = await getUserData(userID);
      const eventsData = await getUserEvents(userID);
      const coachesData = await getUserCoaches(userID);
      const groupsData = await getUserGroups(userID);

      console.log(`coaches from db: ${JSON.stringify(coachesData)}`);
      setCoaches(coachesData);
      console.log(`events from db: ${JSON.stringify(eventsData)}`);
      setEvents(eventsData);
      console.log(`groups from db: ${JSON.stringify(groupsData)}`);
      setGroups(groupsData);

      setUserData(userData);
      setInitialDataRecieved(true);
    })();
  }, [userID]);

  const getUserDataAgain = async () => {
    const userData = await getUserData(userID);
    setUserData(userData);
    return userData;
  };

  // Syncing the data that is changed by the user with db
  useEffect(() => {
    // If the origin data recieved from server, we can sync our side to the db (or else we will wipe the server data)
    (async function syncData() {
      if (initalDataRecieved) {
        syncUserEvents(userID, events);
        console.log(`syncing events`);
      }
    })();
  }, [events, initalDataRecieved]);

  useEffect(() => {
    // If the origin data recieved from server, we can sync our side to the db (or else we will wipe the server data)
    (async function syncData() {
      if (initalDataRecieved) {
        syncUserCoaches(userID, coaches);
        console.log(`syncing coaches`);
      }
    })();
  }, [coaches, initalDataRecieved]);

  useEffect(() => {
    // If the origin data recieved from server, we can sync our side to the db (or else we will wipe the server data)
    (async function syncData() {
      if (initalDataRecieved) {
        syncUserGroups(userID, groups);
        console.log(`syncing groups`);
      }
    })();
  }, [groups, initalDataRecieved]);

  const openEventFormModal = () => {
    setShowEventFormModal(true);
  };

  const closeEventModal = () => {
    setShowEventFormModal(false);
    setEventName("");
    setEventTime("");
    setEventDate("");
    setEventDescription("");
    setEditedEvent(null);
  };

  const handleEventNameChange = (event) => {
    setEventName(event.target.value);
  };

  const handleEventTimeChange = (event) => {
    setEventTime(event.target.value);
  };

  const handleEventDateChange = (event) => {
    setEventDate(event.target.value);
  };

  const handleEventDescriptionChange = (event) => {
    setEventDescription(event.target.value);
  };

  const handleEventSubmit = () => {
    if (editedEvent) {
      const updatedEvent = {
        ...editedEvent,
        name: eventName,
        time: eventTime,
        date: eventDate,
        description: eventDescription,
      };
      const updatedEvents = events.map((event) =>
        event === editedEvent ? updatedEvent : event
      );
      setEvents(updatedEvents);
    } else {
      const newEvent = {
        name: eventName,
        time: eventTime,
        date: eventDate,
        description: eventDescription,
      };
      setEvents([...events, newEvent]);
    }
    closeEventModal();
  };

  const openEventEditModal = (event) => {
    setEditedEvent(event);
    setEventName(event.name);
    setEventTime(event.time);
    setEventDate(event.date);
    setEventDescription(event.description);
    setShowEventFormModal(true);
  };

  const onEventDelete = (event) => {
    const updatedEvents = events.filter((e) => e !== event);
    setEvents(updatedEvents);
  };

  const openCoachModal = () => {
    setShowCoachModal(true);
  };

  const closeCoachModal = () => {
    setShowCoachModal(false);
    setCoachName("");
    setCoachPhoneNumber("");
    setEditedCoach(null);
  };

  const handleCoachNameChange = (event) => {
    setCoachName(event.target.value);
  };

  const handleCoachPhoneNumberChange = (event) => {
    setCoachPhoneNumber(event.target.value);
  };

  const handleCoachSubmit = () => {
    if (editedCoach) {
      const updatedCoach = {
        ...editedCoach,
        name: coachName,
        phoneNumber: coachPhoneNumber,
      };
      const updatedCoaches = coaches.map((coach) =>
        coach === editedCoach ? updatedCoach : coach
      );
      setCoaches(updatedCoaches);
    } else {
      const newCoach = {
        name: coachName,
        phoneNumber: coachPhoneNumber,
      };
      setCoaches([...coaches, newCoach]);
    }
    closeCoachModal();
  };

  const openEditCoachModal = (coach) => {
    setEditedCoach(coach);
    setCoachName(coach.name);
    setCoachPhoneNumber(coach.phoneNumber);
    setShowCoachModal(true);
  };

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [coachToDelete, setCoachToDelete] = useState(null);

  const openDeleteModal = (coach) => {
    setCoachToDelete(coach);
    setShowDeleteModal(true);
  };

  const closeDeleteModal = () => {
    setCoachToDelete(null);
    setShowDeleteModal(false);
  };

  const handleCoachDelete = () => {
    const updatedCoaches = coaches.filter((coach) => coach !== coachToDelete);
    setCoaches(updatedCoaches);
    closeDeleteModal();
  };

  const practiceDays = [
    "יום ראשון",
    "יום שני",
    "יום שלישי",
    "יום רביעי",
    "יום חמישי",
    "יום שישי",
    "יום שבת",
  ];

  const handleGroupNameChange = (event) => {
    setGroupName(event.target.value);
  };

  const handleGroupClubNameChange = (event) => {
    setGroupClubName(event.target.value);
  };

  const handleGroupCoachChange = (event) => {
    setGroupCoach(event.target.value);
  };

  const toggleDay = (day) => {
    if (selectedDays.includes(day)) {
      setSelectedDays(
        selectedDays.filter((selectedDay) => selectedDay !== day)
      );
    } else {
      setSelectedDays([...selectedDays, day]);
    }
  };

  const handleGroupFormSubmit = (event) => {
    event.preventDefault();

    // Create a new group object with the selected practice days
    const newGroup = {
      name: groupName,
      clubName: groupClubName,
      coach: groupCoach,
      practiceDays: selectedDays,
      startHours: startHours,
      trainees: 0,
      endHours: endHours,
    };

    // Add the new group to the groups array
    setGroups([...groups, newGroup]);
    closeGroupModal();

    // Clear the form and selectedDays state
    setSelectedDays([]);
  };

  const openGroupModal = () => {
    setShowGroupModal(true);
  };

  const closeGroupModal = () => {
    setShowGroupModal(false);
  };

  // Function to handle changes in start hours
  const handleStartHourChange = (day, value) => {
    setStartHours((prevstate) => {
      prevstate[day] = value;
      return prevstate;
    });
  };

  // Function to handle changes in end hours
  const handleEndHourChange = (day, value) => {
    setEndHours((prevstate) => {
      prevstate[day] = value;
      return prevstate;
    });
  };

  const handleOpenGroupDetails = (group) => {
    setSelectedGroup(group);
    console.log(group);
    setIsGroupDetailsModalOpen(true);
  };

  const handleCloseGroupDetailsModal = () => {
    setIsGroupDetailsModalOpen(false);
  };

  return (
    <div className="page-container">
      <Container className="dashboard-container">
        {userData && (
          <Tab.Container defaultActiveKey="plan">
            <Nav variant="tabs" className="dashboard-nav">
              <Nav.Item>
                <Nav.Link eventKey="coaches">המאמנים שלי</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="events">אירועים</Nav.Link>
              </Nav.Item>
              {/* <Nav.Item>
                <Nav.Link eventKey="plan">תוכנית אימונים</Nav.Link>
              </Nav.Item> */}
              <Nav.Item>
                <Nav.Link eventKey="groups">קבוצות ומועדונים</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Button
                  variant="light"
                  className="logout-button"
                  onMouseEnter={(e) => e.target.classList.add("hovered")}
                  onMouseLeave={(e) => e.target.classList.remove("hovered")}
                  onClick={() => setShowLogoutModal(true)}
                >
                  <BiLogOutCircle size={24} />
                </Button>
              </Nav.Item>
              <Modal
                show={showLogoutModal}
                onHide={() => setShowLogoutModal(false)}
              >
                <Modal.Header className="hebrewHeader">
                  <div className="hebrewHeader d-flex justify-content-between w-100 align-items-center">
                    <Modal.Title>התנתקות</Modal.Title>
                    <Button
                      variant="light"
                      onClick={() => setShowLogoutModal(false)}
                      className="close-button"
                    >
                      &times;
                    </Button>
                  </div>
                </Modal.Header>
                <Modal.Body className="hebrewText">
                  האם אתה בטוח שברצונך להתנתק?
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    variant="secondary"
                    onClick={() => setShowLogoutModal(false)}
                  >
                    ביטול
                  </Button>
                  <Button variant="danger" onClick={handleLogout}>
                    התנתק
                  </Button>
                </Modal.Footer>
              </Modal>
            </Nav>

            <Tab.Content className="dashboard-content">
              <Tab.Pane eventKey="coaches">
                {/* תוכן המאמנים שלי */}
                <div className="helpHeader">
                  <h2>המאמנים שלי</h2>
                  <HelpBubble
                    text={`במסך זה תוכל להוסיף ולהסיר מאמנים אשר ברצונך להוסיף לארגון ולהשתמש באפליקציית SimplyCoach תחת ארגונך. \n מאמנים אלו יקבלו ממך עדכונים, תוכן, אירועים ותוכל לעקוב אחר תפקודם בארגון בצורה מהירה וקלילה!`}
                  />
                </div>
                {/* כאן יש להציג את תוכן המאמנים */}
                <div className="coachesContainer">
                  {coaches?.map((coach, index) => (
                    <div key={index} className="coachCard">
                      <div className="coachDetails">
                        <p className="coachName">שם המאמן: {coach.name}</p>
                        <p className="coachPhoneNumber">
                          מספר הטלפון: {coach.phoneNumber}
                        </p>
                      </div>
                      <div className="coachActions">
                        <Button
                          variant="primary"
                          className="edit-button"
                          onClick={() => openEditCoachModal(coach)}
                        >
                          ערוך
                        </Button>
                        <Button
                          variant="danger"
                          className="delete-button"
                          onClick={() => openDeleteModal(coach)}
                        >
                          מחק
                        </Button>
                      </div>
                    </div>
                  ))}
                </div>
                <Button variant="success" onClick={openCoachModal}>
                  הוסף מאמן חדש
                </Button>
              </Tab.Pane>

              <Tab.Pane eventKey="events">
                {/* אירועים */}
                <div className="eventsHeaderContainer">
                  <div className="helpHeader">
                    <h2>אירועים</h2>
                    <Button
                      variant="success"
                      className="marginRight"
                      onClick={openEventFormModal}
                    >
                      צור אירוע חדש
                    </Button>
                  </div>
                  <HelpBubble
                    text={`במסך האירועים תוכל ליצור ולנהל את כלל אירועי הארגון, כך שיופיעו לכלל המאמנים בארגון באפליקציית SimplyCoach. \n 
                    `}
                  />
                </div>
                {events?.map((event, index) => (
                  <EventCard
                    key={index}
                    event={event}
                    onEdit={openEventEditModal}
                    onDelete={onEventDelete}
                  />
                ))}
              </Tab.Pane>

              {/* <Tab.Pane eventKey="plan">
                <div className="helpHeader">
                  <h2>תוכנית האימונים</h2>
                  <HelpBubble text={`אהלן`} />
                </div>
              </Tab.Pane> */}

              <Tab.Pane eventKey="groups">
                <div className="helpHeader">
                  <h2>קבוצות ומועדונים</h2>
                  <HelpBubble
                    text={`במסך הקבוצות ומועדונים תוכל/י ליצור ולנהל את כלל הקבוצות והמועדונים בארגון. תוכל לשייך לכל קבוצה מאמן ולעקוב אחר פעילות הקבוצות - הכל ממקום אחד! \n `}
                  />
                </div>

                <Button variant="success" onClick={openGroupModal}>
                  הוסף קבוצה חדשה
                </Button>

                {/* Display the list of groups */}
                <div className="groupsContainer">
                  <div className="groupsContainer">
                    {groups
                      ?.slice()
                      .sort((a, b) => a.clubName?.localeCompare(b.clubName))
                      ?.map((group, index) => (
                        <div
                          key={index}
                          className={`groupCard`}
                          onClick={() => handleOpenGroupDetails(group)}
                        >
                          <div className="groupHeader">
                            <p className="groupName">שם הקבוצה: {group.name}</p>
                            <p className="groupClubName">
                              שם המועדון: {group.clubName}
                            </p>
                            <p className="groupCoach">מאמן: {group.coach}</p>
                          </div>
                          {/* Render selected days */}
                          {group.practiceDays?.map((day) => {
                            return (
                              <div key={Math.random()}>
                                <p className="hebrewText" key={day}>
                                  {day}, {group.startHours[day]}-
                                  {group.endHours[day]}
                                </p>
                              </div>
                            );
                          })}
                        </div>
                      ))}
                  </div>
                </div>

                {/** Group Details Modal */}
                {selectedGroup && (
                  <Modal
                    show={isGroupDetailsModalOpen}
                    onHide={handleCloseGroupDetailsModal}
                  >
                    <Modal.Header className="eventFormHeader">
                      <Modal.Title className="ml-auto">
                        {selectedGroup.name}
                      </Modal.Title>
                      <Button
                        variant="light"
                        className="close-button"
                        onClick={closeEventModal}
                      >
                        &times;
                      </Button>
                    </Modal.Header>
                    <Modal.Body className="hebrewText">
                      <p>מספר מתאמנים: {selectedGroup.trainees}</p>
                      <p>מאמן: {selectedGroup.coach}</p>
                      <p>
                        {selectedGroup.practiceDays?.map((day) => {
                          return (
                            <div key={Math.random()}>
                              <p className="hebrewText" key={day}>
                                {day}, {selectedGroup.startHours[day]}-
                                {selectedGroup.endHours[day]}
                              </p>
                            </div>
                          );
                        })}
                      </p>
                      {/* Include drop rate graph */}
                      <div className="dropRateGraph">
                        {/* Add your drop rate graph component here */}
                      </div>
                    </Modal.Body>
                    <Modal.Footer>
                      <Button
                        variant="secondary"
                        onClick={handleCloseGroupDetailsModal}
                      >
                        סגור
                      </Button>
                    </Modal.Footer>
                  </Modal>
                )}
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        )}

        {/** Event Form Modal */}
        <Modal show={showEventFormModal} onHide={closeEventModal}>
          <Modal.Header className="eventFormHeader">
            <Modal.Title className="ml-auto">הוסף אירוע חדש</Modal.Title>
            <Button
              variant="light"
              className="close-button"
              onClick={closeEventModal}
            >
              &times;
            </Button>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group
                className="eventFormFieldContainer"
                controlId="eventName"
              >
                <Form.Label>שם האירוע</Form.Label>
                <Form.Control
                  type="text"
                  className="eventFormInput"
                  value={eventName}
                  onChange={handleEventNameChange}
                />
              </Form.Group>
              <Form.Group
                className="eventFormFieldContainer"
                controlId="eventTime"
              >
                <Form.Label>שעת האירוע</Form.Label>
                <Form.Control
                  type="time"
                  className="eventFormInput"
                  value={eventTime}
                  onChange={handleEventTimeChange}
                />
              </Form.Group>
              <Form.Group
                className="eventFormFieldContainer"
                controlId="eventDate"
              >
                <Form.Label>תאריך האירוע</Form.Label>
                <Form.Control
                  type="date"
                  className="eventFormInput"
                  value={eventDate}
                  onChange={handleEventDateChange}
                />
              </Form.Group>
              <Form.Group
                className="eventFormFieldContainer"
                controlId="eventDescription"
              >
                <Form.Label>תיאור האירוע</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  className="eventFormInput"
                  value={eventDescription}
                  onChange={handleEventDescriptionChange}
                />
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={closeEventModal}>
              ביטול
            </Button>
            <Button variant="primary" onClick={handleEventSubmit}>
              שמירה
            </Button>
          </Modal.Footer>
        </Modal>

        {/** Coach Form Modal */}
        <Modal show={showCoachModal} onHide={closeCoachModal}>
          <Modal.Header className="eventFormHeader">
            <Modal.Title className="ml-auto">הוסף מאמן חדש</Modal.Title>
            <Button
              variant="light"
              className="close-button"
              onClick={closeCoachModal}
            >
              &times;
            </Button>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group
                className="eventFormFieldContainer"
                controlId="coachName"
              >
                <Form.Label>שם המאמן</Form.Label>
                <Form.Control
                  type="text"
                  className="eventFormInput"
                  value={coachName}
                  onChange={handleCoachNameChange}
                />
              </Form.Group>
              <Form.Group
                className="eventFormFieldContainer"
                controlId="coachPhoneNumber"
              >
                <Form.Label>מספר הטלפון</Form.Label>
                <Form.Control
                  type="text"
                  className="eventFormInput"
                  value={coachPhoneNumber}
                  onChange={handleCoachPhoneNumberChange}
                />
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={closeCoachModal}>
              ביטול
            </Button>
            <Button variant="primary" onClick={handleCoachSubmit}>
              שמירה
            </Button>
          </Modal.Footer>
        </Modal>

        {/* Group Form Modal */}
        <Modal
          show={showGroupModal}
          onHide={closeGroupModal}
          className="groupFormModal"
        >
          <Modal.Header className="groupFormHeader hebrewHeader">
            <Modal.Title className="ml-auto">הוסף קבוצה חדשה</Modal.Title>
            <Button
              variant="light"
              className="close-button"
              onClick={closeGroupModal}
            >
              &times;
            </Button>
          </Modal.Header>
          <Modal.Body>
            <Form onSubmit={handleGroupFormSubmit}>
              <Form.Group
                className="groupFormFieldContainer"
                controlId="groupName"
              >
                <Form.Label className="hebrewText">שם הקבוצה</Form.Label>
                <Form.Control
                  type="text"
                  className="groupFormInput"
                  dir="rtl"
                  value={groupName}
                  onChange={handleGroupNameChange}
                />
              </Form.Group>
              <Form.Group
                className="groupFormFieldContainer"
                controlId="groupClubName"
              >
                <Form.Label>שם המועדון</Form.Label>
                <Form.Control
                  type="text"
                  className="groupFormInput"
                  dir="rtl"
                  value={groupClubName}
                  onChange={handleGroupClubNameChange}
                />
              </Form.Group>
              <Form.Group
                className="groupFormFieldContainer"
                controlId="groupCoach"
              >
                <Form.Label>מאמן</Form.Label>
                <Form.Control
                  as="select"
                  className="groupFormInput"
                  value={groupCoach}
                  dir="rtl"
                  onChange={handleGroupCoachChange}
                >
                  <option value="">בחר מאמן</option>
                  {coaches?.map((coach) => (
                    <option key={coach.id} value={coach.name}>
                      {coach.name}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
              <Form.Group
                className="groupFormFieldContainer"
                controlId="practiceDays"
              >
                <Form.Label>ימי אימון</Form.Label>
                <div className="toggleDaysContainer">
                  {practiceDays?.map((day) => (
                    <Button
                      key={day}
                      variant={
                        selectedDays.includes(day)
                          ? "primary"
                          : "outline-primary"
                      }
                      className={`toggleDayButton ${
                        selectedDays.includes(day) ? "active" : ""
                      }`}
                      onClick={() => toggleDay(day)}
                    >
                      {day}
                    </Button>
                  ))}
                </div>
              </Form.Group>
              {selectedDays?.map((day) => (
                <div key={day} className="timeRangeContainer">
                  <Form.Group
                    className="groupFormFieldContainer"
                    controlId={`startHour-${day}`}
                  >
                    <Form.Label>שעת התחלה - {day}</Form.Label>
                    <Form.Control
                      type="time"
                      className="groupFormInput"
                      dir="rtl"
                      onChange={(e) =>
                        handleStartHourChange(day, e.target.value)
                      }
                    />
                  </Form.Group>
                  <Form.Group
                    className="groupFormFieldContainer"
                    controlId={`endHour-${day}`}
                  >
                    <Form.Label>שעת סיום - {day}</Form.Label>
                    <Form.Control
                      type="time"
                      className="groupFormInput"
                      onChange={(e) => handleEndHourChange(day, e.target.value)}
                    />
                  </Form.Group>
                </div>
              ))}
              <div className="buttonsContainer">
                <Button variant="secondary" onClick={closeGroupModal}>
                  ביטול
                </Button>
                <Button variant="primary" type="submit">
                  שמירה
                </Button>
              </div>
            </Form>
          </Modal.Body>
        </Modal>

        {/* Coach Delete Modal */}
        <Modal show={showDeleteModal} onHide={closeDeleteModal}>
          <Modal.Header className="hebrewHeader">
            <Modal.Title>מחיקת מאמן</Modal.Title>
            <Button
              variant="light"
              className="close-button"
              onClick={closeDeleteModal}
            >
              &times;
            </Button>
          </Modal.Header>
          <Modal.Body>
            <p className="hebrewText">
              האם אתה בטוח שברצונך למחוק את המאמן{" "}
              {coachToDelete && coachToDelete.name}?
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={closeDeleteModal}>
              ביטול
            </Button>
            <Button variant="danger" onClick={handleCoachDelete}>
              מחק
            </Button>
          </Modal.Footer>
        </Modal>

        <ActivationModal
          activationStatus={
            userData?.activationStatus ? userData?.activationStatus : false
          }
          getUserDataAgain={getUserDataAgain}
        />
      </Container>
      <Container className="dashboard-container">
        <div className="text-container">
          <BsFillRocketFill className="rocket-icon" />
          <p className="text">בקרוב, סטטיסטיקה על כלל הארגון</p>
        </div>
      </Container>
    </div>
  );
};

export default Dashboard;
