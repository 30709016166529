import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";

// Custom
import NewStudentForm from "./pages/NewStudentForm";
import ManagerLogIn from "./pages/Manager Auth Components/ManagerLogIn";
import ManagerDashboard from "./pages/Manager Dashboard Components/ManagerDashboard";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Navigate to="/manager/auth" />} />
        <Route
          path="/newstudent/:coachType/:userType/:ID/:groupID"
          element={<NewStudentForm />}
        />
        <Route path="/manager/auth" element={<ManagerLogIn />} />
        <Route
          path="/manager/auth/dashboard/:userID"
          element={<ManagerDashboard />}
        />
        <Route
          path="/usagepolicy"
          element={
            <div className="pdf-container">
              <iframe
                src="https://docs.google.com/gview?url=https://drive.google.com/uc?id=1UasHp-oJvFryaDV4UpsKkkSLIYe-5J4-&embedded=true"
                title="Usage Policy"
                className="pdf-iframe"
              />
            </div>
          }
        />
      </Routes>
    </Router>
  );
}

export default App;
