import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";

import { useState } from "react";

import { getAge, isValidEmail } from "../NewStudentForm";

export default function ParentsDetailsPhase(props) {
  const setProgress = props.setProgress;
  const setNewParentsData = props.setNewParentsData;
  const newParentsData = props.newParentsData;
  const newStudentData = props.newStudentData;

  const studentAge = getAge(
    new Date(
      newStudentData.birthYear,
      newStudentData.birthMonth - 1,
      newStudentData.birthDay
    )
  );

  const [parentOneName, setParentOneName] = useState(
    studentAge < 19
      ? newParentsData.parentOneName
        ? newParentsData.parentOneName
        : ""
      : newStudentData.personalName
      ? newStudentData.personalName
      : ""
  );
  const [parentOneNameTouched, setParentOneNameTouched] = useState(
    newParentsData.parentOneName ? true : false
  );
  const [parentOneNameValid, setParentOneNameValid] = useState(true);

  const [parentOnePhone, setParentOnePhone] = useState(
    newParentsData.parentOnePhone ? newParentsData.parentOnePhone : ""
  );
  const [parentOnePhoneTouched, setParentOnePhoneTouched] = useState(
    newParentsData.parentOnePhone ? true : false
  );
  const [parentOnePhoneValid, setParentOnePhoneValid] = useState(true);

  const [parentOneMail, setParentOneMail] = useState(
    newParentsData.parentOneMail ? newParentsData.parentOneMail : ""
  );
  const [parentOneMailTouched, setParentOneMailTouched] = useState(
    newParentsData.parentOneMail ? true : false
  );
  const [parentOneMailValid, setParentOneMailValid] = useState(true);

  const [parentTwoName, setParentTwoName] = useState(
    newParentsData.parentTwoName ? newParentsData.parentTwoName : ""
  );
  const [parentTwoNameTouched, setParentTwoNameTouched] = useState(
    newParentsData.parentTwoName ? true : false
  );
  const [parentTwoNameValid, setParentTwoNameValid] = useState(true);

  const [parentTwoPhone, setParentTwoPhone] = useState(
    newParentsData.parentTwoPhone ? newParentsData.parentTwoPhone : ""
  );
  const [parentTwoPhoneTouched, setParentTwoPhoneTouched] = useState(
    newParentsData.parentTwoPhone ? true : false
  );
  const [parentTwoPhoneValid, setParentTwoPhoneValid] = useState(true);

  const [parentTwoMail, setParentTwoMail] = useState(
    newParentsData.parentTwoMail ? newParentsData.parentTwoMail : ""
  );
  const [parentTwoMailTouched, setParentTwoMailTouched] = useState(
    newParentsData.parentTwoMail ? true : false
  );
  const [parentTwoMailValid, setParentTwoMailValid] = useState(true);

  return (
    <div className="contentContainer">
      <p className="newtext">{studentAge >= 19 ? `פרטי קשר` : `פרטי הורה 1`}</p>
      <Form className="formStyles">
        <Form.Group className="mb-3">
          {studentAge < 19 ? (
            <Form.Control
              type="text"
              className="newtext newsmallerText newblackText formField parentField"
              placeholder={`שם`}
              value={parentOneName}
              id="personalName"
              onChange={(e) => {
                setParentOneName(e.target.value);
              }}
              onBlur={() => {
                if (!parentOneNameTouched) setParentOneNameTouched(true);
                if (parentOneName.trim().length > 0)
                  setParentOneNameValid(true);
                else setParentOneNameValid(false);
              }}
              isInvalid={!parentOneNameValid}
            />
          ) : null}
          <Form.Control
            type="text"
            className="newtext newsmallerText newblackText formField parentField"
            placeholder={`מייל`}
            value={parentOneMail}
            id="personalName"
            onChange={(e) => {
              setParentOneMail(e.target.value);
            }}
            onBlur={() => {
              if (!parentOneMailTouched) setParentOneMailTouched(true);
              if (isValidEmail(parentOneMail)) setParentOneMailValid(true);
              else setParentOneMailValid(false);
            }}
            isInvalid={!parentOneMailValid}
          />
          <Form.Control
            type="text"
            className="newtext newsmallerText newblackText formField parentField"
            placeholder={"טלפון"}
            value={parentOnePhone}
            id="personalName"
            onChange={(e) => {
              setParentOnePhone(e.target.value);
            }}
            onBlur={() => {
              if (!parentOnePhoneTouched) setParentOnePhoneTouched(true);
              if (parentOnePhone.trim().length === 10)
                setParentOnePhoneValid(true);
              else setParentOnePhoneValid(false);
            }}
            isInvalid={!parentOnePhoneValid}
          />
        </Form.Group>
        {studentAge < 19 ? (
          <>
            <p className="newtext">פרטי הורה 2</p>
            <Form.Group className="parentField">
              <Form.Control
                type="text"
                className="newtext newblackText newsmallerText formField"
                placeholder="שם"
                value={parentTwoName}
                id="personalName"
                onChange={(e) => {
                  setParentTwoName(e.target.value);
                }}
                onBlur={() => {
                  if (!parentTwoNameTouched) setParentTwoNameTouched(true);
                  if (parentTwoName.trim().length > 0)
                    setParentTwoNameValid(true);
                  else setParentTwoNameValid(false);
                }}
                isInvalid={!parentTwoNameValid}
              />
              <Form.Control
                type="text"
                className="newtext newsmallerText newblackText formField parentField"
                placeholder="מייל"
                value={parentTwoMail}
                id="personalName"
                onChange={(e) => {
                  setParentTwoMail(e.target.value);
                }}
                onBlur={() => {
                  if (!parentTwoMailTouched) setParentTwoMailTouched(true);
                  if (isValidEmail(parentTwoMail)) setParentTwoMailValid(true);
                  else setParentTwoMailValid(false);
                }}
                isInvalid={!parentTwoMailValid}
              />
              <Form.Control
                type="text"
                className="newtext newsmallerText newblackText formField parentField"
                placeholder="טלפון"
                value={parentTwoPhone}
                id="personalName"
                onChange={(e) => {
                  setParentTwoPhone(e.target.value);
                }}
                onBlur={() => {
                  if (!parentTwoPhoneTouched) setParentTwoPhoneTouched(true);
                  if (parentTwoPhone.trim().length === 10)
                    setParentTwoPhoneValid(true);
                  else setParentTwoPhoneValid(false);
                }}
                isInvalid={!parentTwoPhoneValid}
              />
            </Form.Group>
          </>
        ) : null}
      </Form>
      <Button
        variant="primary"
        className="newtext newsmallerText"
        onClick={() => {
          setNewParentsData({
            parentOneName: parentOneName,
            parentOneMail: parentOneMail,
            parentOnePhone: parentOnePhone,
            parentTwoName: parentTwoName,
            parentTwoMail: parentTwoMail,
            parentTwoPhone: parentTwoPhone,
          });
          setProgress(80);
        }}
        disabled={
          !(
            /**Parent One Is Valid */
            (
              (parentOneMailTouched &&
                parentOneMailValid &&
                parentOnePhoneTouched &&
                parentOnePhoneValid &&
                ((parentOneNameValid && parentOneNameTouched) ||
                  studentAge >= 19)) ||
              /**Parent Two Is Valid */
              (parentTwoMailTouched &&
                parentTwoMailValid &&
                parentTwoPhoneTouched &&
                parentTwoPhoneValid &&
                parentTwoNameValid &&
                parentTwoNameTouched)
            )
          )
        }
      >
        המשך
      </Button>
    </div>
  );
}
